
import React from 'react'

const routes = [
  {
    path: '/',
    component: React.lazy(() => import('../pages/home')),
    // 如果要求严格路径
    isExact: true,
    meta:{
      title: 'memeland'
    }
  }
]

export default routes
