import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Suspense} from 'react'
//routes是路由列表配置，将在下面展示
import routes from './routes/index'

function App() {
  return (
    <div className="App">

      <Suspense fallback={<div style={{  display: 'flex',flexDirection: 'column',flex:1,
          justifyContent: 'center',
          alignItems: 'center',marginTop:"50%"}}>loading...</div>}>
        <Router>
          <Switch>
            {routes.map((item,index) => (<Route exact={item.isExact} component={item.component} path={item.path} key={index}/>))}
          </Switch>

        </Router>
      </Suspense>
    </div>
  );
}

export default App;